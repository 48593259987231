/* eslint-disable */
import * as ExcelJS from 'exceljs';
import React, { useMemo } from 'react';
import {
	Box,
	Grid,
	Select,
	Button,
	MenuItem,
	TextField,
	IconButton,
	InputLabel,
	FormControl,
	InputAdornment,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { GetApp, Search } from '@material-ui/icons';

import { exportQCVToXlsx, exportToXlsx as exportKVCToXlsx, capitalizeFirstLetter } from '../../../../utils';
import { FORM_CONSTANTS, MONOGRAPHS_CONSTANTS, qcvEnums, REPORTS_CONSTANTS } from '../../../../constants';
import { ACHIEVEMENT_TYPE, APP_SCENE, KEYS, ROLE } from '../../../../constants/enums';
import { useScene } from '../../../../hooks/useScene';
import sample from "../../../../assets/template/9.KVC2024_Mẫu đánh giá chung về hồ sơ xét chọn_091024 (1).xlsx";
import dayjs from 'dayjs';

export default function DanhSachHoSoFind(props) {
  const scene = useScene();
	const { role, field, setField } = props;

  const fieldOptions = useMemo(() => ([
    { key: 'all', title: 'Toàn bộ', value: 'all' },
    ...FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE]
  ]), [APP_SCENE]);

  const fieldName = useMemo(() => fieldOptions.find((e) => e.value === field)?.title, [fieldOptions, field]);

	const handleChangeName = (e) => props.setName(e.target.value);
	const handleChangeField = (e) => {
		if (role === ROLE.SUPER_ADMIN) sessionStorage.setItem('admin_field', e.target.value);
		setField(e.target.value);
	}

  const exportDanhGiaChung = async (forms, field, fieldName) => {
      // Fetch the Excel file from the public folder
      const response = await fetch(sample);
      const arrayBuffer = await response.arrayBuffer();
  
      // Load the Excel file into ExcelJS
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);
  
      // Access the first worksheet
      const worksheet = workbook.getWorksheet('Sheet1');
  
      // Modify a specific cell value, e.g., changing A1 cell
      const cell = worksheet.getCell('A1');
      cell.value = cell.value + ' ' + fieldName.toUpperCase();
      
      forms.forEach((form, id) => {
        console.log(id)
        // TODO: Đoạn này dùng chung
        const detail = { ...form };
        Object.keys(ACHIEVEMENT_TYPE).forEach((key) => {
          const _key = 'form' + capitalizeFirstLetter(ACHIEVEMENT_TYPE[key]);
          detail[ACHIEVEMENT_TYPE[key]] = detail[_key]
            .map((e) => ({
              ...e[ACHIEVEMENT_TYPE[key]],
              note: e.note,
              score: e.score,
              accepted: e.accepted,

              [`${_key}Id`]: e.id,
              thanhTichAccepted: e.accepted,
            }))
            .sort((a, b) => a.id - b.id);;
        })

        detail.formResearchProject = detail.formResearchProject.map((e) => ({
          ...e,
          // TODO: Sửa backend cái json này
          start_at: dayjs(e.start_at).format('DD/MM/YYYY'),
          finish_at: dayjs(e.finish_at).format('DD/MM/YYYY'),
        }));

        detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT].filter(
          (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
        );
        detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT].filter(
          (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
        );
        detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.MONOGRAPH].filter(
          (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
        );
        detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.MONOGRAPH].filter(
          (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
        );
        detail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT] = detail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT].map((e) => ({
          ...e,
          start_at: dayjs(e.start_at).format('DD/MM/YYYY'),
          finish_at: dayjs(e.finish_at).format('DD/MM/YYYY'),
        }));
        detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.DOMESTIC);
        detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.INTERNATIONAL);
    
        detail.summary = JSON.parse(detail.summary || '[]');
        detail.process = JSON.parse(detail.process || '[]');
        detail.socialActivity = JSON.parse(detail.socialActivity || '[]');
        detail.personalAchievement = JSON.parse(detail.personalAchievement || '[]');
        detail.communicationActivity = JSON.parse(detail.communicationActivity || '[]');

        const fileList = JSON.parse(detail.previewFile || '[]');
        detail.sumaryFile = fileList.find((file) => file.includes(('sumaryFile')));
        detail.previewFile = fileList.filter((file) => file.includes(('previewFile'))); //.map((file) => photoRender(file));

        worksheet.getCell(`A${7 + id}`).value = id + 1;
        worksheet.getCell(`B${7 + id}`).value = detail?.profile?.name;
        if (detail?.profile?.gender === 'male') {
          worksheet.getCell(`C${7 + id}`).value = 'x';
        } else if (detail?.profile?.gender === 'female') {
          worksheet.getCell(`D${7 + id}`).value = 'x';
        }
        worksheet.getCell(`E${7 + id}`).value = detail.unit;
        worksheet.getCell(`F${7 + id}`).value = FORM_CONSTANTS.ACADEMIC_LEVEL_OPTIONS.find((e) => e.value === detail.academicLevel)?.title;
        worksheet.getCell(`G${7 + id}`).value = detail.scoreD;
        worksheet.getCell(`H${7 + id}`).value = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL].reduce((total, item) => total + item.score, 0);
        worksheet.getCell(`I${7 + id}`).value = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL].reduce((total, item) => total + item.score, 0);
        worksheet.getCell(`J${7 + id}`).value = detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL].reduce((total, item) => total + item.score, 0);
        worksheet.getCell(`K${7 + id}`).value = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC].reduce((total, item) => total + item.score, 0);
        worksheet.getCell(`L${7 + id}`).value = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC].reduce((total, item) => total + item.score, 0);
        worksheet.getCell(`M${7 + id}`).value = detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC].reduce((total, item) => total + item.score, 0);
        worksheet.getCell(`N${7 + id}`).value = detail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT].reduce((total, item) => total + item.score, 0);
        worksheet.getCell(`O${7 + id}`).value = detail.scoreB;
        worksheet.getCell(`P${7 + id}`).value = detail.scoreC;
        worksheet.getCell(`Q${7 + id}`).value = Math.min(detail.scoreA, 50) + detail.scoreB + detail.scoreC;
        'ABCDEFGHIJKLMNOPQRST'.split('').forEach((c) => {
          const cell = worksheet.getCell(`${c}${7 + id}`);
          cell.alignment = { wrapText: true, vertical: 'middle', ... cell.alignment },
          cell.border = {
            top: { style:'thin' },
            left: { style:'thin' },
            bottom: { style:'thin' },
            right: { style:'thin' }
          }
        });
      });
      
      // Export the modified Excel file
      const modifiedBuffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([modifiedBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "KVC Đánh giá chung về hồ sơ xét chọn.xlsx");
  }

	return (
		<>
			<Grid item xs={6} md={2}>
				<Button
					disabled={field === 'all'}
					fullWidth
					variant='contained'
					color='secondary'
					startIcon={<GetApp />}
					onClick={() => {
            const forms = props.rows
              .map((e, id) => ({ ...e, realID: id + 1 }))
              .sort((a, b) => (
                (b.scoreA + b.scoreB + b.scoreC + b.scoreD) - (a.scoreA + a.scoreB + a.scoreC + a.scoreD)
              ));
            
            if (scene.isKhueVanCac) {
              exportDanhGiaChung(forms, field, fieldName);
            }
          }}
					style={{ color: 'white', height: 40 }}
				>
					Xuất file Đánh giá
				</Button>
			</Grid>
      <Grid item xs={6} md={2}>
				<Button
					disabled={field === 'all'}
					fullWidth
					variant='contained'
					color='secondary'
					startIcon={<GetApp />}
					onClick={() => {
            const forms = props.rows
              .map((e, id) => ({ ...e, realID: id + 1 }))
              .sort((a, b) => (
                (b.scoreA + b.scoreB + b.scoreC + b.scoreD) - (a.scoreA + a.scoreB + a.scoreC + a.scoreD)
              ));
            
            if (scene.isQuaCauVang) {
              exportQCVToXlsx(forms, field, fieldName);
            } else if (scene.isKhueVanCac) {
              exportKVCToXlsx(forms, field, fieldName);
            }
          }}
					style={{ color: 'white', height: 40 }}
				>
					Xuất file chấm
				</Button>
			</Grid>
			<Grid item xs={12} md={4}>
				<FormControl size='small' variant='outlined' fullWidth>
					<InputLabel id="test-select-label">Lĩnh vực</InputLabel>
					<Select
						fullWidth
						disabled={role?.includes('qcv_admin_') || role?.includes('kvc_admin_')}
						value={field || ''}
						MenuProps={{
							getContentAnchorEl: null,
							MenuListProps: { style: { padding: 0 } },
							PaperProps: { style: { left: 8 } },
							anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
							transformOrigin: { vertical: 'top', horizontal: 'center' },
						}}
						onChange={handleChangeField}
						label='Lĩnh vực'
					>
						{fieldOptions.map((opt) => <MenuItem key={opt.key} value={opt.key}>{opt.title}</MenuItem>)}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={4}>
				<Box width='100%' display='flex' justifyContent='center'>
					<TextField
						fullWidth
						size='small'
						label='Tìm kiếm ứng viên'
						variant='outlined'
						onChange={handleChangeName}
						InputProps={{ endAdornment: 
							<InputAdornment position='end'>
								<IconButton type='submit'>
									<Search />
								</IconButton>
							</InputAdornment>
						}}
					/>
				</Box>
			</Grid>
		</>
	)
}