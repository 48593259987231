import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Step,
  Stepper,
  StepLabel,
  Typography,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { matchSorter } from 'match-sorter';
import { useSelector, useDispatch} from 'react-redux';

import { formActions } from '../../../../actions';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { APP_SCENE, FORM_CONSTANTS, ROUTER, SECONDARY_COLOR } from '../../../../constants';

import svg from '../../../../assets/image/danhsachhoso/svg.png';
import KPTable from '../../../../components/KPTComponents/KPTable';
import StatusChip from '../../../../components/CustomComponents/StatusChip/StatusChip';
import DanhSachHoSoFind from '../DanhSachHoSoFind';

export default function DanhSachHoSoTable() {
	const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

	const seasonList = useSelector(state => state.seasonReducer.seasonList);
	const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);
	const applicationList = useSelector(state => state.formReducer.applicationList);

	const [name, setName] = useState(null);
  const [field, setField] = useState(null);

	useEffect(() => {
		if (openSeasonId) {
			dispatch(formActions.filterApplicationForm({
				seasonId: openSeasonId,
				category: APP_SCENE,
				status: ['in_review', 'submitted', 'approved']
			}));
		}
	}, [openSeasonId]);

	var columns = [
		{ title: <b>STT</b>, dataIndex: 'id', width: '10%', render: (_, __, id) => id + 1 },
		{ title: <b>Tên ứng viên</b>, dataIndex: 'profile', width: '30%', render: (value) => value?.name },
		{
			title: <b>Nhóm ngành</b>,
			dataIndex: 'field',
			width: '30%',
			render: (value) => FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE].find((e) => e.value === value)?.title
		},
		{
			title: <b>Năm đăng ký</b>,
			dataIndex: 'seasonId',
			width: '20%',
			render: (value) => seasonList.find((e) => e.id === value)?.year,
		},
		{
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      width: '30%',
      filters: [
        { text: 'Đang hoàn thiện', value: 'unsubmit' },
        { text: 'Đã nộp', value: 'in_review' },
        { text: 'Đã được duyệt', value: 'approved' },
      ],
      onFilter: (value, row) => row.status.includes(value),
      render: (value) => <StatusChip status={value} />,
    },
		{
      title: <b>Tác vụ</b>,
      dataIndex: 'actions',
      width: '15%',
      render: (_, row) => <NavLink to={`${ROUTER.HO_SO}/${row.id}`}>Xem hồ sơ</NavLink>
    }
	];

	if (isMobileMode) columns = [
    {
			...columns[1],
			width: '38%',
			render: (value, row, id) => (
				<>
					<b>{id + 1}. {value?.name}</b>
					<div>{`(Lĩnh vực: ${row.major})`}</div>
				</>
			)
		},
    // {...columns[3], title: <b>Năm</b>, width: '12%'},
		{...columns[4], width: '35%'},
    {...columns[5], width: '20%'},
  ];

	var rows = matchSorter(applicationList || [], name || "", { keys: ["profile.name"] });
  if (field) rows = field === "all" ? rows : rows.filter((e) => e.field === field);

	return (
		<Grid container spacing={2}>
      <Grid item xs={4}>
        <Card style={{ backgroundColor: "#ececec", height: 164 }}>
          <CardContent style={{ padding: 0 }}>
            <Box width='100%' display='flex' justifyContent='space-between'>
              <Box style={{ padding: 16, paddingRight: 4 }}>
                <Typography variant="h6">
                  <b>Tổng số lượng hồ sơ</b>
                </Typography>
                <Typography variant="h2" style={{ textAlign: 'center' }}>
                  <b>{rows?.length}</b>
                </Typography>
              </Box>
              <img alt="svg" height={164} src={svg} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={8} style={{ height: "100%" }}>
        <Card style={{ backgroundColor: "#ececec", height: 164 }}>
          <CardHeader style={{ paddingRight: 4 }} title={
            <Typography variant="h6">
              <b>Hướng dẫn hội đồng chấm</b>
            </Typography>
            }
            />
          <CardContent style={{ padding: 0 }}>
            <Stepper
              color="secondary"
              style={{ paddingTop: 0, backgroundColor: "transparent" }}
            >
              <Step active>
                <StepLabel StepIconProps={{ style: { color: SECONDARY_COLOR } }} optional="Duyệt và cho điểm từng thành tích của ứng viên">
                  <b>Duyệt thành tích</b>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel active StepIconProps={{ style: { color: SECONDARY_COLOR } }} optional="Duyệt hoặc loại hồ sơ của ứng viên">
                  <b>Duyệt hồ sơ</b>
                </StepLabel>
              </Step>
              <Step active>
                <StepLabel StepIconProps={{ style: { color: SECONDARY_COLOR } }}>
                  <b>Trích xuất báo cáo</b>
                </StepLabel>
              </Step>
            </Stepper>
          </CardContent>
        </Card>
      </Grid>
			<DanhSachHoSoFind
				rows={rows}
				name={name}
				field={field}
				setName={setName}
				setField={setField}
			/>
			<Grid item xs={12}>
				<KPTable columns={columns} rows={rows} />
			</Grid>
		</Grid>
	)
}
