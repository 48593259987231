import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { formActions } from '../../../../actions';
import { APP_SCENE, VOTE_CONSTANTS } from '../../../../constants';
import { useScene } from '../../../../hooks/useScene';

const sortFunction = (a, b) => b?.voteNum - a?.voteNum;

const blendColors = (colorA, colorB, amount) => {
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
  const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0');
  const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0');
  const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0');
  return '#' + r + g + b;
}


function KetQuaBinhChon() {
  const scene = useScene();
  const dispatch = useDispatch();

  const [canAnim, setCanAnim] = useState(false);

  const { academicLevelOptions } = VOTE_CONSTANTS;

	const role = useSelector(state => state.userReducer.user?.role);
	const loading = useSelector(state => state.formReducer.loading);
	const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);
  const applicationListFromServer = useSelector(state => state.formReducer.applicationList);

  const applicationList = useMemo(() => applicationListFromServer.sort(sortFunction), [applicationListFromServer]);
  const longestVote = useMemo(() => applicationList?.[0]?.voteNum, [applicationList]);
  const lengthVoted = useMemo(() => applicationList?.filter((a) => a.voteNum > 0).length, [applicationList]);

  useEffect(() => {
    if (loading) setTimeout(() => setCanAnim(true), 1000);
  }, [loading]);

  useEffect(() => {
    let voteStatus;
    // TODO: Năm sau lưu vote_status vào DB
    if (scene.isQuaCauVang) {
      voteStatus = 'top_10';
    } else if (scene.isGiaiThuongNuSinh) {
      voteStatus = '';
    } else if (scene.isKhueVanCac) {
      voteStatus = 'top_20';
    }
    if (openSeasonId) dispatch(formActions.filterApplicationForm({
			seasonId: openSeasonId,
			category: APP_SCENE,
			status: ['approved'],
      voteStatus,
		}))
	}, [openSeasonId]);

  if (role !=='super_admin') return (
    <Grid item xs={12}>
      <Box display='flex' height='100vh'>
        Bạn không có quyền truy cập!
      </Box>
    </Grid>
  );


  if (loading) return (
    <Grid item xs={12}>
      <Box display='flex' height='100vh'>
        <CircularProgress style={{ margin: 'auto' }} />
      </Box>
    </Grid>
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} style={{ margin: '32px 0 16px' }}>
        <Typography variant='h4' style={{ textAlign: 'center' }}><b>Kết quả bình chọn</b></Typography>
      </Grid>
      <Grid item xs={12} lg={6} xl={4} style={{ marginBottom: 32, padding: '0 8px' }}>
        <Grid container spacing={1}>
          <Grid item xs={1} component={Box} display={{ xs: 'none', sm: 'block' }}>
            <Typography variant='h6'><b>Hạng</b></Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography variant='h6'><b>Họ và tên</b></Typography>
          </Grid>
          <Grid item xs={4} sm={5} />
          <Grid item xs={4} sm={2}>
            <Typography variant='h6' style={{ textAlign: 'end' }}><b>Số phiếu</b></Typography>
          </Grid>
          {applicationList.map((e, id) => (
            <React.Fragment key={e.id}>
              <Grid item xs={12}><Divider /></Grid>
              <Grid item xs={1} component={Box} display={{ xs: 'none', sm: 'block' }}>{id + 1}</Grid>
              <Grid item xs={5} sm={4}>
                <b style={{ textDecoration: 'none', color: 'inherit' }}>
                  {academicLevelOptions.find((item) => item.value === e?.academicLevel)?.title || ''}
                  {e.profile?.name}
                </b>
              </Grid>
              <Grid item xs={4} sm={5}>
                <Box display='flex' alignItems='center' width='100%' height={24} bgcolor='#D3D3D3' borderRadius={6}>
                  <Box
                    height={24}
                    borderRadius={6}
                    width={canAnim ? `${(e?.voteNum) / longestVote * 100}%` : 0}
                    bgcolor={blendColors('#113768', '#0598CE', id / lengthVoted)}
                    style={{ transition: `${0.4 + 0.6 * id / lengthVoted}s` }}
                  >
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3} sm={2} style={{ textAlign: 'end' }}>
                {`${e?.voteNum} phiếu`}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KetQuaBinhChon;
