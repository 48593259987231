/* eslint-disable */
import dayjs from 'dayjs';
import Interweave from 'interweave';
import React, { useState, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import KPTable from '../../../../components/KPTComponents/KPTable';
import HosoAbout from '../components/about';
import HosoProfile from '../components/profile';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
import ThanhTichInsert from '../../ThanhTichInsert/ThanhTichInsert';
import MultiPreviewModal from '../../../../components/CustomComponents/MultiPreviewModal/MultiPreviewModal';
import DuyetThanhTichModalV2 from '../../../../components/CustomComponents/DuyetThanhTichModalV2';

import { columnRender } from '../../../../utils/columnRender';
import { hoSoColumnMap } from '../../../../utils/qcv/hoSo.column_table';
import { APP_SCENE, ACHIEVEMENT_TYPE, KEYS } from '../../../../constants/enums';
import { kvcAchievementFields, capitalizeFirstLetter } from '../../../../utils';
import { MONOGRAPHS_CONSTANTS, REPORTS_CONSTANTS, ROUTER } from '../../../../constants';
import { formActions, profileActions, scoreInformationActions } from '../../../../actions';


export default function HoSoViewAdmin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [duyetThanhTich, setDuyetThanhTich] = useState(null);

  const arrayPath = window.location.href.split('/');
  const urlId = parseInt(arrayPath.slice(arrayPath.length - 1));

	const focusForm = useSelector((state) => state.formReducer.focusForm);
  const profileId = useSelector((state) => state.profileReducer.profile?.id);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);
  const applicationList = useSelector((state) => state.formReducer.applicationList);
  const scoreInformationList = useSelector((state) => state.scoreInformationReducer.scoreInformationList);

  const handleAccept = (row = duyetThanhTich) => {}

  useEffect(() => {
    if (applicationList.length === 0) dispatch(profileActions.getProfileList());
  }, []);

  useEffect(() => {
    if (openSeasonId) dispatch(scoreInformationActions.getScoreInformationBySeasonId(openSeasonId));
  }, [openSeasonId]);

  useEffect(() => {
    if (applicationList) {
      const applicationDetail = applicationList.find((e) => e.id === urlId);
      dispatch(formActions.setFocusForm(applicationDetail));
    }
  }, [applicationList]);

  const applicationDetail = useMemo(() => {
    const detail = { ...focusForm };
    if (focusForm) {
      Object.keys(ACHIEVEMENT_TYPE).forEach((key) => {
        const _key = 'form' + capitalizeFirstLetter(ACHIEVEMENT_TYPE[key]);
        detail[ACHIEVEMENT_TYPE[key]] = detail[_key]
          .map((e) => ({
            ...e[ACHIEVEMENT_TYPE[key]],
            note: e.note,
            score: e.score,
            accepted: e.accepted,

            [`${_key}Id`]: e.id,
            thanhTichAccepted: e.accepted,
          }))
          .sort((a, b) => a.id - b.id);;
      })

      console.log(detail);

      detail.formResearchProject = detail.formResearchProject.map((e) => ({
        ...e,
        // TODO: Sửa backend cái json này
        start_at: dayjs(e.start_at).format('DD/MM/YYYY'),
        finish_at: dayjs(e.finish_at).format('DD/MM/YYYY'),
      }));

      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT].filter(
        (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT].filter(
        (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.MONOGRAPH].filter(
        (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.MONOGRAPH].filter(
        (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT] = detail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT].map((e) => ({
        ...e,
        start_at: dayjs(e.start_at).format('DD/MM/YYYY'),
        finish_at: dayjs(e.finish_at).format('DD/MM/YYYY'),
      }));
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.DOMESTIC);
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.INTERNATIONAL);
  
      detail.summary = JSON.parse(detail.summary || '[]');
      detail.process = JSON.parse(detail.process || '[]');
      detail.socialActivity = JSON.parse(detail.socialActivity || '[]');
      detail.personalAchievement = JSON.parse(detail.personalAchievement || '[]');
      detail.communicationActivity = JSON.parse(detail.communicationActivity || '[]');

      const fileList = JSON.parse(detail.previewFile || '[]');
      detail.sumaryFile = fileList.find((file) => file.includes(('sumaryFile')));
      detail.previewFile = fileList.filter((file) => file.includes(('previewFile'))); //.map((file) => photoRender(file));
    }
    return detail;
  }, [focusForm]);
 
  const actions = useMemo(() => {
    if (props.isSuperAdmin) return ['link', 'edit', 'score'];
    else if (applicationDetail.profile?.id === profileId) return ['link', 'detail'];
    else return ['link'];
  }, [applicationDetail, profileId, props.isSuperAdmin]);

  const achievementFields = kvcAchievementFields({ achievementData: applicationDetail, actions });

  useEffect(() => {
    if (applicationDetail && applicationDetail?.profileId) {
      if (!props.isSuperAdmin && applicationDetail?.profileId !== profileId) {
        navigate(ROUTER.PAGE_401);
      }
    }
  }, [props.isSuperAdmin, applicationDetail]);

  return (
    <>
      <Grid container style={{ padding: 32 }}>
        <HosoProfile applicationDetail={applicationDetail} />
        <HosoAbout applicationDetail={applicationDetail} />
        <Typography>
          <b>05 ảnh ứng viên tham gia các hoạt động học tập, nghiên cứu khoa học, hoạt động vì cộng đồng: </b>
          <a href='#' onClick={() => setPreviewFile(applicationDetail.previewFile)}>
            Xem tại đây
          </a>
        </Typography>
        
        <Grid item xs={5} style={{ marginTop: 32, marginBottom: 8 }}>
          <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>
            QUÁ TRÌNH HỌC TẬP, CÔNG TÁC
          </Box>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12}>
          <KPTable
            nonBorder={false}
            columns={hoSoColumnMap[APP_SCENE].hocTapCongTac()}
            rows={applicationDetail?.process}
          />
        </Grid>
        <Grid item xs={5} style={{ marginTop: 32, marginBottom: 8 }}>
          <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>
            TÓM TẮT CÔNG TRÌNH TIÊU BIỂU
          </Box>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12}>
          {applicationDetail?.summary?.map((sum, id) => (
            <Interweave key={`sum_${id}`} content={sum} />
          ))}
        </Grid>
        <Grid item xs={5} style={{ marginTop: 32, marginBottom: 8 }}>
          <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>
            THÀNH TÍCH CÁ NHÂN
          </Box>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <Box display='flex'>
            <Box
              width={32}
              height={32}
              color='white'
              display='flex'
              bgcolor='black'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
            >
              A
            </Box>
            <Box flexGrow={1} paddingLeft={4} lineHeight='32px'>
              <Typography variant='h6'>
                <b>THÀNH TÍCH KHOA HỌC</b>
              </Typography>
            </Box>
          </Box>
        </Grid>
        {achievementFields.map((achivement, id) => (
          <Grid item xs={12} key={achivement.index}>
            {achivement.children?.map((child) => (
              <>
                <Box display='flex' margin='8px 0'>
                  <Typography variant='h6'><b>{child.index}. {child.title}</b></Typography>
                </Box>
                <KPTable nonBorder={false} rows={child.data} columns={
                  props.isSuperAdmin
                    ? columnRender(child, setDialog, setPreviewFile, setDuyetThanhTich)
                    : columnRender(child, setDialog, setPreviewFile, null)
                } />
              </>
            ))}
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 64 }}>
          <Box display='flex'>
            <Box
              width={32}
              height={32}
              color='white'
              display='flex'
              bgcolor='black'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
            >
              B
            </Box>
            <Box flexGrow={1} paddingLeft={4} lineHeight='32px'>
              <Typography variant='h6'>
                <b>Thành tích khen thưởng (Bằng khen, giấy khen...)</b>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <Grid item xs={12}>
            <KPTable
              nonBorder={false}
              columns={hoSoColumnMap[APP_SCENE].thanhTichBangKhen((value) => setPreviewFile(value))}
              rows={applicationDetail?.communicationActivity}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 64 }}>
          <Box display='flex'>
            <Box
              width={32}
              height={32}
              color='white'
              display='flex'
              bgcolor='black'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
            >
              C
            </Box>
            <Box flexGrow={1} paddingLeft={4} lineHeight='32px'>
              <Typography variant='h6' style={{ marginBottom: 10 }}>
                <b>Hoạt động chia sẻ tri thức, tập huấn, đào tạo,...</b>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <KPTable
            nonBorder={false}
            columns={hoSoColumnMap[APP_SCENE].hoatDongChiaSeTriThuc((value) => setPreviewFile(value))}
            rows={applicationDetail?.personalAchievement}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 64 }}>
          <Box display='flex'>
            <Box
              width={32}
              height={32}
              color='white'
              display='flex'
              bgcolor='black'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
            >
              D
            </Box>
            <Box flexGrow={1} paddingLeft={4} lineHeight='32px'>
              <Typography variant='h6' style={{ marginBottom: 10 }}>
                <b>Hoạt động tình nguyện vì cộng đồng nói chung</b>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <KPTable
            nonBorder={false}
            columns={hoSoColumnMap[APP_SCENE].hoatDongCongDong((value) => setPreviewFile(value))}
            rows={applicationDetail?.socialActivity}
          />
        </Grid>
      </Grid>

      <ThanhTichInsert dialog={dialog} setDialog={setDialog} />
      <PreviewModal open={!!previewFile && typeof(previewFile) === 'string'} url={previewFile} handleClose={() => setPreviewFile(null)} />
      <MultiPreviewModal open={!!previewFile && typeof(previewFile) !== 'string'} url={previewFile} handleClose={() => setPreviewFile(null)} />
      <DuyetThanhTichModalV2
        info={duyetThanhTich}
        open={!!duyetThanhTich}
        handleAccept={handleAccept}
        values={values}
        setValues={setValues}
        scoreInformationList={scoreInformationList}
        handleClose={() => setDuyetThanhTich(null)}
      />
    </>
  );
}
